import React, { useState, useEffect } from 'react';
import Landing from './Landing/Landing';
// import PhotoCompare from './PhotoCompare/PhotoCompare';
import PhotoInfo from './PhotoInfo/PhotoInfo';
import GoogleAuth from'./PhotoCompare/GoogleAuth';

type Route = '/' | '/photo-compare' | '/photo-info' | '/edit' | '/view';

// Unique ID Cookie
const generateUniqueId = (): string => Math.random().toString(36).substr(2, 10) + Math.random().toString(36).substr(2, 10)
const getCookie = (name: string): string | null => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
};

// Function to set a cookie
const setCookie = (name: string, value: string, days: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + encodeURIComponent(value) + "; expires=" + date.toUTCString() + "; path=/";
};

const App: React.FC = () => {
  const [route, setRoute] = useState<Route>(window.location.pathname as Route);
  
  useEffect(() => {
    const handlePopState = () => {
      setRoute(window.location.pathname as Route);
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  const navigate = (path: Route) => {
    window.history.pushState({}, "", path);
    setRoute(path);
  };
  const renderRoute = () => {
    switch (route) {
      case '/photo-compare': return <GoogleAuth navigate={navigate} />;
      case '/photo-info': return <PhotoInfo navigate={navigate} />;
      default: return <Landing navigate={navigate} />;
    }
  };

  useEffect(() => {
    const cookieName = 'photo-loop-unique-id';
    let uniqueId = getCookie(cookieName);
    if (!uniqueId) {
      uniqueId = generateUniqueId();
      setCookie(cookieName, uniqueId, 365); // Set cookie for 1 year
    }
  }, []);
  return (
    <div className="App">{renderRoute()}</div>
  );
}

export default App;
