import React, { useRef, ChangeEvent, useState, DragEvent } from 'react'
import './UploadImage.scss'
import CanvasSettings from '../CanvasSettings/CanvasSettings'

const UploadImage: React.FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const hideElement = useRef<HTMLDivElement>(null)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [image, setImage] = useState<string | null>(null)

  // get Image data
  const getImageData = (file: File | undefined) => {
    setImageLoaded(true)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => setImage(reader.result as string)
      reader.readAsDataURL(file)
    }
  }

  // click to upload image
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => getImageData(event.target.files?.[0])

  // drad and drop to upload image
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => event.preventDefault()
  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    getImageData(event.dataTransfer.files?.[0])
  }
  return (
    <section className='uploadimage' onDrop={handleDrop} onDragOver={handleDragOver} ref={hideElement}>
      <div className={`upload-txt ${imageLoaded ? 'hide' : ''}`}>Click to Upload / Drop Image</div>
      <input type="file" className={`upload-file-input ${imageLoaded ? 'upload-file-input-replace' : ''}`} ref={fileInputRef} accept="image/*" onChange={handleFileChange} />
      {imageLoaded && ( <CanvasSettings getImage={image} /> )}
    </section>
  )
}

export default UploadImage