import { useEffect } from 'react';

const useDisableBounceEffect = () => {
  useEffect(() => {
    const preventBounce = (event: TouchEvent) => {
      // Check if the page is scrolled to the top or bottom
      const scrollTop = document.scrollingElement?.scrollTop || 0;
      const scrollHeight = document.scrollingElement?.scrollHeight || 0;
      const clientHeight = window.innerHeight;

      if (scrollTop === 0 && event.touches[0].clientY > 0) {
        // Prevent pull down at the top
        event.preventDefault();
      } else if (scrollTop + clientHeight >= scrollHeight) {
        // Prevent pull up at the bottom
        event.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventBounce, { passive: false });

    return () => {
      document.removeEventListener('touchmove', preventBounce);
    };
  }, []);
};

export default useDisableBounceEffect;
