import React from 'react';
import useDisableBounceEffect from '../Others/useDisableBounceEffect';
import UploadImage from './UploadImage/UploadImage';
import Footer from '../Footer/Footer';

type Props = { navigate: (path: '/' | '/photo-compare' | '/photo-info' | '/edit' | '/view') => void; }

const PhotoInfo: React.FC<Props> = ({ navigate }) => {
  useDisableBounceEffect();
  return (
    <>
    <main className="main-area">
      <UploadImage />
    </main>
    <Footer navigate={navigate} />
    </>
  );
};

export default PhotoInfo;