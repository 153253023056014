import React, { useRef, useEffect, useState } from 'react'
import './CanvasSettings.scss'

interface CanvasImageProps { getImage: any }

const CanvasSettings: React.FC<CanvasImageProps> = ({ getImage }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    useEffect(() => {
        const canvas = canvasRef.current
        if (canvas) {
            const getCanvasWidthHeight = canvas.getBoundingClientRect()
            const canvasWidth = getCanvasWidthHeight.width
            const canvasHeight = getCanvasWidthHeight.height
            const ctx = canvas.getContext('2d')
            if (ctx) {
                const image = new Image()
                image.src = getImage
                image.onload = () => {
                    const imgWidth = imgRef.current?.width || 0
                    const imgHeight = imgRef.current?.height || 0
                    canvas.width = canvasWidth
                    canvas.height = canvasHeight
                    ctx.clearRect(0, 0, canvasWidth, canvasHeight)
                    ctx.drawImage(image, 0, 0, imgWidth/2, imgHeight/2)
                }
            }
        }
    }, [getImage])
    return(
        <>
        <img src={getImage} ref={imgRef} className='hidden-image' alt='dummy' />
        <canvas ref={canvasRef}  />
        <ol className='canvas-options'>
            <li className='replace'><span>REPLACE</span></li>
        </ol>
        </>
    )
}

export default CanvasSettings
