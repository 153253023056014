type Props = { navigate: (path: '/' | '/photo-compare' | '/photo-info' | '/edit' | '/view') => void; }

const Footer: React.FC<Props> = ({ navigate }) => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className="footer">
        <ol>
          <li className="share disable"><span>Themes</span></li>
          <li className="share disable"><span>SHARE</span></li>
          <li className="share disable"><span>SHARE</span></li>
          <li className="logo" onClick={() => navigate('/')}></li>
        </ol>
      </div>
      <div className="footer2">© {year} <b>P</b>hoto <b>L</b>oop</div>
    </footer>
  );
};

export default Footer;
