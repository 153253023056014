import React from 'react';
import GoogleAuthComponent from '../Others/GoogleAuthComponent';

type Props = { navigate: (path: '/' | '/photo-compare' | '/photo-info' | '/edit' | '/view') => void; }

const GoogleAuth: React.FC<Props> = ({ navigate }) => {
  return (
    <GoogleAuthComponent />
  );
};

export default GoogleAuth;