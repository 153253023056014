import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const clientId = "YOUR_GOOGLE_CLIENT_ID";

const GoogleAuthComponent: React.FC = () => {
  const handleSuccess = (response: any) => {
    console.log("Login Success", response);
    // You can access response.credential and send it to your backend for further processing
  };

  const handleFailure = () => {
    console.error("Login Failed");
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div>
        <h2>Login with Google</h2>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleFailure}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuthComponent;