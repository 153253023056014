import React from 'react';
import './Landing.scss';
import logo from '../assets/images/logo.svg';
import idea from '../assets/images/idea.svg';
import noads from '../assets/images/Ads.svg';
import HighPerformance from '../assets/images/HighPerformance.svg';
import EfficientTool from '../assets/images/EfficientTool.svg';
import phone from '../assets/images/phone.svg';
import email from '../assets/images/email.svg';
import location from '../assets/images/location.svg';

type Props = { navigate: (path: '/' | '/photo-compare' | '/photo-info' | '/edit' | '/view') => void; }
const year = new Date().getFullYear();

const Landing: React.FC<Props> = ({ navigate }) => {
    return (
    <main className='main-cls'>
        <header className="header">
            <h1 className="hide">Welcome to Photo Loop</h1>
            <p className="hide">Your one-stop solution for photo comparison, editing, viewing, and information analysis.</p>
            <img className="logo" src={logo} alt="logo" />
            <p className="para">Photo Loop is a web-based photo editing and comparison tool that allows users to upload, edit, and compare images in an intuitive and user-friendly interface. The application aims to cater to both casual users and professionals who want to enhance or analyze images without the need for heavyweight desktop software.</p>
        </header>

        <section className="section section1">
            <h3 className="head">Our Approach</h3>
            <p className="para">We aim to streamline photo editing by enhancing user experience through intuitive design and efficient tools. By simplifying the editing process, we make it faster and more accessible, allowing users to achieve professional-quality results in a fraction of the time. Ultimately, our goal is to empower users with powerful editing capabilities that save time without compromising quality.</p>
            <img className="logo" src={idea} alt="idea" />
        </section>

        <section className="section section2">
            <h3 className="head">Our Apps</h3>
            <ol className="list">
                <li onClick={() => navigate('/photo-compare')}>
                    <h2 className="list-head">Photo Compare</h2>
                    <p className="list-para">Our tool allows you to compare photos and make quick edits with ease. You can resize images, add shapes, and insert text to customize your pictures. It’s perfect for testing purposes. Once you're done editing, you can easily share the link for others to view or continue editing.</p>
                    <button>Click Me</button>
                </li>
                <li onClick={() => navigate('/photo-info')}>
                    <h2 className="list-head">Photo Info.</h2>
                    <p className="list-para">Our tool provides comprehensive information about your images, including width, height, file size, format, and more. Perfect for analysing and working with your photos efficiently.</p>
                    <button>Click Me</button>
                </li>
                <li className='disable'>
                    <h2 className="list-head">Photo Edit</h2>
                    <p className="list-para">This tool allows users to enhance images with a variety of built-in and custom filters. You can easily resize and crop images to achieve the perfect look, ensuring your photos turn out just right.</p>
                </li>
                <li className='disable'>
                    <h2 className="list-head">Photo View</h2>
                    <p className="list-para">Easily add multiple images and arrange them in collage mode to create stunning visuals. You can also choose from a variety of custom filters to enhance your images and apply them with just a few clicks.</p>
                </li>
            </ol>
        </section>

        <section className="section section3">
            <h3 className="head">Why Choose PhotoLoop?</h3>
            <ol className="list">
                <li>
                    <img src={noads} alt="No Ads" className="logo" />
                    <h2 className="list-head">No Ads</h2>
                    <p className="list-para">out application not showing any ads so you can perform better without any distraction</p>
                </li>
                <li>
                    <img src={HighPerformance} alt="High Performance" className="logo" />
                    <h2 className="list-head">High Performance</h2>
                    <p className="list-para">we use high quality code to make sure high performance with out any lag or glitch</p>
                </li>
                <li>
                    <img src={EfficientTool} alt="Efficient Tool" className="logo" />
                    <h2 className="list-head">Efficient tool</h2>
                    <p className="list-para">We use a minimal tool that offers all the necessary features to perform major tasks effortlessly</p>
                </li>
            </ol>
        </section>

        <section className="section section4">
            <h3 className="head">Get in Touch</h3>
            <p className="para">Whether you have questions, feedback, or just want to say hello, our team is here to help! At Photo Loop, we’re dedicated to providing the best experience for our users, and your input is invaluable to us.</p>
            <br/>
            <h3 className="head">Contact Us</h3>
            <ol className="list">
                <li><img className="logo" src={phone} alt="phone"/>7892606365</li>
                <li><img className="logo" src={email} alt="phone"/>photolooponline@gmail.com</li>
                <li><img className="logo" src={location} alt="phone"/>******</li>
            </ol>
        </section>

        <footer className="footer-cls">
            <p>&copy; {year} Photo Loop. All rights reserved.</p>
        </footer>
</main>
  );
};

export default Landing;
